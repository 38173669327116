import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useForm, Controller } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { DateTime } from 'luxon'
import { answerTicket } from '../../../../http/ticket'
import css from './styles.module.scss'
import WYSIWYGEditor from '../../../../components/common/WYSIWYG'

export default function ModalTicket({ show, handleClose, ticket, setLoading }) {
  const {
    handleSubmit, setValue, reset, getValues, control,
  } = useForm()

  useEffect(() => {
    const fields = ['answer']
    if (ticket.id) {
      fields.forEach((field) => setValue(field, ticket[field]))
      return
    }
    fields.forEach((field) => setValue(field, null))
  }, [ticket, setValue])

  const onSubmit = async (data) => {
    setLoading(true)
    answerTicket(ticket.id, data.answer).then(e => {
      NotificationManager.success('A resposta foi salva com sucesso', 'Salvo com sucesso')
      close()
    }).catch((e) => {
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }).finally(e => {
      setLoading(false)
    })
  }

  const close = () => {
    reset()
    handleClose()
  }
  
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['dialog']}
    >
      <Modal.Header closeButton>
        <Modal.Title>Responder ticket</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <p><b>Requisitante:</b> {ticket.user?.name}</p>
          <p><b>Assunto:</b> {ticket.title}</p>
          <p><b>Criado em:</b> {DateTime.fromJSDate(new Date(ticket.createdAt)).toFormat("dd/M/yyyy HH:mm")}</p>
          {ticket.message && <p><b>Descrição:</b> {ticket.message}</p>}
          
          <Form.Group className="mb-3">
            <Form.Label><b>Resposta</b></Form.Label>
            <Controller
              render={ () => 
                <WYSIWYGEditor 
                  value={getValues('answer')}
                  onChange={value => {
                    setValue('answer', value)
                  }} 
                /> 
              }
              name='answer'
              control={control}
              //defaultValue=''
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button type="submit" variant="success">Responder</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
